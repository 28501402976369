import React from 'react';
import Carousel from 'ui/elements/Carousel'
import OpticalHeading from 'ui/elements/Heading/OpticalHeading'
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Section } from 'components/Layout/styles';

import Speaker from './Speaker';

const PastSpeakers = ({
  conference,
}) => {
  const { t } = useTranslation('conference')
  const speakers = conference.pastSpeakers;

  if (!speakers || !speakers.length) {
    return null;
  }

  return (
    <Section>
      <OpticalHeading renderAs="h2" level={4}>
        {t('pastSpeakers')}
      </OpticalHeading>

      <Carousel responsive={{
        xs: { items: 1 },
        sm: { items: 1 },
        md: { items: 1 },
        lg: { items: 1 },
        xl: { items: 1 },
      }}>
        {
          speakers.map((speaker, i) => (
            <Speaker key={i} speaker={speaker}  />
          ))
        }
      </Carousel>
    </Section>
  )
};

export default PastSpeakers;
