import React from 'react';
import { Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Button from 'ui/elements/Button'
import Heading from 'ui/elements/Heading'
import { Heading as SHeading }  from 'ui/elements/Heading/styles'
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import _flow from 'lodash/flow';

import ContentBox from 'widgets/ContentBox';
import Link from 'widgets/Link';
import { slugify } from 'utils/helpers';

import Date from 'components/shared/Date'
import MeetingActions from 'components/shared/MeetingActions'
import MeetingSummary from 'components/shared/MeetingSummary'
import { Section } from 'components/Layout/styles';

import { palette, isUpcomingConference } from './utils';

const S = {};
S.ConferenceTitle = styled(SHeading)`
  text-decoration: underline;
  margin-bottom: 15px;
`;

const Upcoming = ({
  conferences,
}) => {
  const { t } = useTranslation(['conference', 'common']);

  const allUpcomingConferences = _flow(
    (data) => _filter(data, isUpcomingConference),
    (data) => _sortBy(data, ['startDate'])
  )(conferences);

  if (!allUpcomingConferences.length) {
    return null;
  }

  const upcoming = allUpcomingConferences[0];
  const to = slugify(upcoming.title);

  return (
    <Section>
      <Heading level={2} color={palette.accent}>
        {t('upcomingConferences')}
      </Heading>

      <S.ConferenceTitle as="h3" level={4} color={palette.accent}>
        {upcoming.title}
      </S.ConferenceTitle>

      <Row align='center' justify='end'>
        <Col xs={12} md={7}>
          <MeetingSummary
            date={[
              <Date date={upcoming.startDate} />,
              '-',
              <Date date={upcoming.endDate} />,
            ]}
            location={upcoming.location}
            fees={upcoming.fee}
            language={t('common:english')}
            color={palette.accent}
            inline
          />
        </Col>
        <Col xs={12} md={5}>
          <MeetingActions inline>
            <Button variant='outlined' color={palette.accent}>
              {t('downloadBrochure')}
            </Button>
            <Button
              iconName='arrow-right'
              as={Link}
              to={`/conferences/${to}`}
              color={palette.accent}
            >
              {t('common:findOutMore')}
            </Button>
          </MeetingActions>
        </Col>
      </Row>

      <ContentBox blocks={upcoming._rawIntro} />
    </Section>
  )
}

export default Upcoming;
