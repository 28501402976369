import React from 'react';
import { Row, Col } from 'react-grid-system';
import OpticalHeading from 'ui/elements/Heading/OpticalHeading';
import Button from 'ui/elements/Button';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Image from 'widgets/Image';
import ContentBox from 'widgets/ContentBox';
import Link from 'widgets/Link';
import { buildPersonaData, slugify } from 'utils/helpers';

import { palette } from '../utils';

const Speaker = ({
  speaker,
}) => {
  const { t } = useTranslation(['conference', 'common']);
  const { fullName, position } = buildPersonaData(speaker);
  const to = slugify(`${speaker.firstName} ${speaker.lastName}`);

  return (
    <Row>
      <Col xs={12} md={4}>
        <Image
          {...speaker.image}
          width={600}
          alt={fullName}
        />
      </Col>
      <Col xs={12} md={7}>
        <header>
          <OpticalHeading level={3}>{fullName}</OpticalHeading>
          <OpticalHeading level={5}>{position}</OpticalHeading>
        </header>

        {speaker._rawBio && (<>
          <ContentBox blocks={speaker._rawBio} truncateLines={4} />

          <Button
            iconName='arrow-right'
            as={Link}
            variant='outlined'
            to={`the-experience/faculty/${to}`}
            color={palette.accent}
          >
            {t('common:findOutMore')}
          </Button>
        </>)}
      </Col>
    </Row>
  )
};

export default Speaker;
