import React from 'react';
import { graphql } from 'gatsby';
import { Hidden } from 'react-grid-system';
import Heading from 'ui/elements/Heading';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import _get from 'lodash/get';

import ContentBox from 'widgets/ContentBox';
import { flattenResponse } from 'utils/helpers';
import withLocalization from 'decorators/withLocalization';
import { Section } from 'components/Layout/styles';
import MeetingLayout from 'components/Layout/MeetingLayout';

import { palette } from 'modules/Conferences/utils';

import PastConferences from 'modules/Conferences/PastConferences'
import PastSpeakers from 'modules/Conferences/PastSpeakers'
import Upcoming from 'modules/Conferences/Upcoming'

const ConferencesIndex = ({
  data: rawData,
  location,
}) => {
  const { t, i18n } = useTranslation('conference');
  const conferences = flattenResponse(rawData, 'allSanityConference');
  const data = _get(rawData, 'sanityConferencesIndex');

  const layoutProps = {
    palette,
    icon: 'conferences',
    title: 'Conferences',
    data,
  };

  const heroProps = {
    heading: null,
  }

  return (
    <MeetingLayout {...layoutProps} heroProps={heroProps}>
      <GatsbySeo
        title='Conferences'
        openGraph={{
          title: 'Conferences',
          url: location.href,
          locale: i18n.language,
        }}
      />
      <Section>
        <Hidden xs sm>
          <Heading color={palette.variant} level={1}>
            {t('conferences')}
          </Heading>
        </Hidden>

        <ContentBox blocks={data._rawContent} />
      </Section>

      <Upcoming conferences={conferences} />

      <PastSpeakers conference={data} />

      <PastConferences conferences={conferences} />
    </MeetingLayout>
  )
}

export const query = graphql`
  fragment ConferencePreview on SanityConference {
    title
    slug
    startDate
    endDate
    fee
    _rawIntro(resolveReferences: { maxDepth: 1 })
    hero {
      gatsbyImageData(
        width: 457
        height: 182
      )
    }
    language
    location
  }
  query ConferencesIndex($id: String, $language: String!) {
    sanityConferencesIndex(id: { eq: $id }) {
      hero {
        ...HeroFragment
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
      pastSpeakers {
        ...PersonaFragment
      }
    }

    allSanityConference(sort: {fields: startDate, order: DESC}) {
      edges {
        node {
          ...ConferencePreview
        }
      }
    }

    locales: allLocale(filter: { language: {eq: $language} }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default withLocalization(ConferencesIndex);
