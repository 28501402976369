import React from 'react';

import Button from 'ui/elements/Button'
import Carousel from 'ui/elements/Carousel'
import Heading from 'ui/elements/Heading'
import HeroPolygon from 'ui/elements/Hero/Polygon'

import _filter from 'lodash/filter';
import _orderBy from 'lodash/orderBy';
import _flow from 'lodash/flow';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Link from 'widgets/Link';
import Image from 'widgets/Image';
import { Section } from 'components/Layout/styles';

import { palette, isPastConference } from './utils';

const PastConferences = ({
  conferences,
}) => {
  const { t } = useTranslation('conference');

  const past = _flow(
    (data) => _filter(data, isPastConference),
    (data) => _orderBy(data, ['startDate'], ['desc']),
  )(conferences);

  if (!past || !past.length) {
    return null;
  }

  return (
    <Section>
      <Heading level={4}>
        {t('pastConferences')}
      </Heading>

      <Carousel responsive={{
        xs: { items: 1 },
        sm: { items: 1 },
        md: { items: 1 },
        lg: { items: 2 },
        xl: { items: 2 },
      }}>
        {
          past.map((conference, i) => (
            <HeroPolygon
              compact
              key={i}
              color={palette.accent}
              heading={conference.title}
              background={
                <Image
                  gatsbyImageData={conference?.hero?.gatsbyImageData}
                  style={{ objectFit: 'cover' }}
                  alt={conference.title}
                />
              }
              action={(
                <Button
                  small
                  as={Link}
                  iconName='arrow-right'
                  variant='outlined'
                  color={palette.accent}
                  to={`/conferences/${conference.slug}`}
                  label={t('takeALook')}
                />
              )}
            />
          ))
        }
      </Carousel>
    </Section>
  )
};

export default PastConferences;

